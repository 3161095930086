export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === 'string') {
        const trimmedPsResult = reader.result.substring(reader.result.indexOf(',') + 1);

        resolve(trimmedPsResult);
      }

      reject(new Error('unable to convert file to base64'));
    };
  });
};
