import React, { useRef, useState, ChangeEvent } from 'react';
import imageCompression from 'browser-image-compression';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { Route } from '../models/site-links';
import Axios from 'axios';
import { blobToBase64 } from '../services/base64-encoder';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import { ErrorMessage } from '@hookform/error-message';
import './privacy-forms.css';

const url = 'https://core.prod.patronscan.servallapps.com/api/v1/privacy/disclosure-request';

type FormValues = {
  firstname: string;
  middlenames?: string;
  surname: string;
  birthdate: Date;
  email: string;
  gender: string;
  idPhoto?: any;
};

const DisclosureRequest = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    criteriaMode: 'all',
  });
  const [base64Image, setBase64] = useState<String>();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      const firstFile = files[0];
      if (firstFile) {
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(firstFile, options);
          const image = await blobToBase64(compressedFile);
          setBase64(image);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const submitForm = async (data: any) => {
    const formData: FormValues = {
      firstname: data.firstname,
      middlenames: data.middlenames,
      surname: data.surname,
      birthdate: data.birthdate,
      email: data.email,
      gender: data.gender,
      idPhoto: base64Image,
    };
    await Axios.post(url, formData).then(() => {
      navigate(Route.Success);
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fieldStyle = 'flex flex-col my-1';
  const labelStyle = 'w-full mt-4 mb-1 w-full tab:w-3/5 desk:w-2/5 font-medium';
  const inputStyle = 'border-blue p-2 rounded-lg border form-text-base w-full tab:w-3/5 desk:w-2/5';
  const radioStyle = 'mr-4';
  const radioLabelStyle = 'my-3 mr-2';
  const errorText = 'text-red mt-1';

  return (
    <div className="w-max mt-32 mx-8">
      <div className="flex-row w-full desk:w-2/3 tab:pl-16">
        <p className="mt-4 font-bold text-2xl">Investigation Request</p>
        <p className="mt-2 mb-2 text-l">Please fill in the form below</p>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="flex-row w-full">
            <div className={fieldStyle}>
              <label className={labelStyle}>First Name*</label>
              <input
                className={inputStyle}
                placeholder="First Name*"
                id="firstname"
                name="firstname"
                type="firstname"
                {...register('firstname', {
                  required: 'Required',
                  maxLength: { value: 50, message: 'Exceeds character limit' },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="firstname"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                }
                as={<p className={errorText} />}
              />
            </div>
            <div className={fieldStyle}>
              <label className={labelStyle}>Middle Name(s)</label>
              <input
                className={inputStyle}
                placeholder="Middle Name(s)"
                id="middlenames"
                name="middlenames"
                type="middlenames"
                {...register('middlenames', {
                  required: false,
                  maxLength: { value: 50, message: 'Exceeds character limit' },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="middlenames"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                }
                as={<p className={errorText} />}
              />
            </div>
            <div className={fieldStyle}>
              <label className={labelStyle}>Last Name*</label>
              <input
                className={inputStyle}
                placeholder="Last Name*"
                id="surname"
                name="surname"
                type="surname"
                {...register('surname', {
                  required: 'Required',
                  maxLength: { value: 50, message: 'Exceeds character limit' },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="surname"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                }
                as={<p className={errorText} />}
              />
            </div>
            <div className={fieldStyle}>
              <label className={labelStyle}>Date of Birth*</label>
              <input
                className={inputStyle}
                id="birthdate"
                name="birthdate"
                type="date"
                {...register('birthdate', {
                  required: 'Required',
                  pattern: {
                    value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                    message:
                      'Invalid input. Try using a different device or email privacy@patronscan.com instead.',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="birthdate"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                }
                as={<p className={errorText} />}
              />
            </div>
            <div className={fieldStyle}>
              <label className={labelStyle}>Email*</label>
              <input
                className={inputStyle}
                placeholder="Email*"
                id="email"
                name="email"
                type="email"
                {...register('email', {
                  required: 'Required',
                  maxLength: { value: 50, message: 'Exceeds character limit' },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => <p key={type}>{message}</p>)
                }
                as={<p className={errorText} />}
              />
            </div>
            <div className={fieldStyle}>
              <label className={`mb-4 ${labelStyle}`}>Gender </label>
              <div className="flex flex-row">
                <div className={radioStyle}>
                  <label className={radioLabelStyle}>Male</label>
                  <input type="radio" id="M" name="gender" value="M" {...register('gender')} />
                </div>
                <div className={radioStyle}>
                  <label className={radioLabelStyle}>Female</label>
                  <input type="radio" id="F" name="gender" value="F" {...register('gender')} />
                </div>
                <div className={radioStyle}>
                  <label className={radioLabelStyle}>Other</label>
                  <input type="radio" id="other" name="gender" value="X" {...register('gender')} />
                </div>
              </div>
            </div>
            <div className={fieldStyle}>
              <label className={`mb-4 ${labelStyle}`}>Please upload a photo of your ID</label>
              <input
                type="file"
                accept="image/*"
                capture
                ref={fileInputRef}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
            <div className={fieldStyle}>
              <p className="mt-2 mb-2 w-full tab:w-3/5 desk:w-2/5">
                Personal information from this form is used only for investigating a patron record
                or flag. Unless required by law, this information will be deleted in 21 days.
              </p>
            </div>
          </div>
          <input
            className={`${ButtonColor.FormSubmission} ${ButtonStyle.CancelOffer}`}
            type="submit"
            disabled={false}
          />
        </form>
      </div>
    </div>
  );
};

export default DisclosureRequest;
